// ANGULAR
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnDestroy
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

// RXJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// CORE
import { LibraryApiService } from '@core/services';
import { dateValidator } from '@core/validators';
import {fadeInRightAnimation} from '@core/animations';

// CURRENT
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-create-library',
  templateUrl: './create-library.component.html',
  styleUrls: ['./create-library.component.scss'],
  animations: [
    fadeInRightAnimation,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateLibraryComponent implements OnDestroy {

  @Input() public isFullForm = true;
  @Output() public libraryCreatedEvent = new EventEmitter<void>();

  public isDisplayedForm = false;
  public typeFieldChosen = '';
  public openedChoiceMap: Set<number> = new Set<number>();
  public isOpenPatternChoice = false;
  public maskParams = {
    mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
    placeholderChar: '\u2000',
  };


  public libraryForm = this.fb.group({
    name: ['', Validators.required],
    index_fields: this.fb.array([]),
    folder_name_pattern: ['', Validators.required],
  });

  private countFields = 4;
  private destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private libraryApiService: LibraryApiService,
              private noticeService: NotificationService) {
  }

  public get indexFields(): FormArray {
    return this.libraryForm.get('index_fields') as FormArray;
  }

  public get indexFieldsValues(): string[] {
    const values = this.libraryForm.get('index_fields').value;

    return values.reduce((arrValues: string[], current: any) => {

      const value = current.name.trim();

      if (value && !arrValues.includes(value)) {
        arrValues.push(value);
      }

      return arrValues;
    }, []);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public toggleLibrary(): void {
    this.isDisplayedForm = !this.isDisplayedForm;
    if (!this.isDisplayedForm) {
      this.closeLibrary();
    }
  }

  public closeLibrary(): void {
    this.libraryForm.reset();
    this.indexFields.clear();
    this.isDisplayedForm = false;
  }

  public getIconField(typeField: string): string {

    switch (typeField) {
      case 'text' : {
        return 'assets/images/library/create-library/text-type.svg';
      }
      case 'date' : {
        return 'assets/images/library/create-library/date-type.svg';
      }
      case 'choice' : {
        return 'assets/images/library/create-library/choice-type.svg';
      }
      case 'number' : {
        return 'assets/images/library/create-library/number-type.svg';
      }
    }
  }

  public addIndexField(typeField: string): void {

    this.typeFieldChosen = typeField;

    if (this.indexFields.length <= this.countFields && this.typeFieldChosen) {
      const validators = typeField === 'date' ? [Validators.required, dateValidator] : [Validators.required];

      const field = this.fb.group({
        name: ['', validators],
        type: [this.typeFieldChosen],
        choices: this.fb.array([], this.typeFieldChosen === 'choice' ? Validators.required : []),
      });

      this.indexFields.push(field);

      this.typeFieldChosen = '';
    }
  }

  public removeIndexField(idx: number): void {
    this.indexFields.removeAt(idx);
  }

  public changeChoice(idx: number): void {
    if (this.openedChoiceMap.has(idx)) {
      this.openedChoiceMap.clear();
    } else {
      this.openedChoiceMap.clear();
      this.openedChoiceMap.add(idx);
    }
  }

  public addChoiceItem(idx: number): void {
    const choiseControl = (this.indexFields.controls[idx].get('choices') as FormArray);

    choiseControl.push(this.fb.control('', Validators.required));
  }

  public removeChoiceItem(idxControl: number, idxItem: number): void {
    const choiseControl = (this.indexFields.controls[idxControl].get('choices') as FormArray);

    choiseControl.removeAt(idxItem);
  }

  public checkPatternBrackets(event: KeyboardEvent): void {

    if (event.target && event.key !== 'Shift') {

      const inputValue = (event.target as HTMLInputElement).value;

      if ((inputValue.length >= 2) && (inputValue.slice(inputValue.length - 2) === '{{')) {
        this.isOpenPatternChoice = true;
      } else {
        this.isOpenPatternChoice = false;
      }
    }
  }

  public addToPattern(event: any): void {
    const selectedOption = event.currentTarget.innerText;
    const pattern = this.libraryForm.get('folder_name_pattern').value;

    this.libraryForm.patchValue({
      folder_name_pattern: `${pattern}${selectedOption}}}`
    });

    this.isOpenPatternChoice = false;
  }

  public onSubmit(): void {
    if (this.libraryForm.invalid) {
      this.libraryForm.markAllAsTouched();
    } else {
      this.libraryApiService.createLibrary(this.libraryForm.value)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe({
          next: (info: any) => {
            this.libraryCreatedEvent.emit();
            this.closeLibrary();
            this.noticeService.setNotice('Library has been successfully created.', 'success');
          },
          error: () => {
          },
        }
      );
    }
  }
}
