// ANGULAR
import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

// RXJS
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// CORE
import { IFolder, ILibrary, ILibraryField } from '@core/interfaces';
import { FolderApiService } from '@core/services';
import { CustomValidators } from '../../../../core/validators';
import { changeFormatDate } from '../../../../core/helpers';

// CURRENT
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFolderComponent implements OnDestroy {

  @Input() public library: ILibrary;
  @Input() public isPopup = false;
  @Output() public folderCreatedEvent = new EventEmitter<IFolder>();

  public isDisplayedForm = false;
  public folderForm: FormGroup;
  public openedChoicetMap: Set<string> = new Set<string>();

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private folderApiService: FolderApiService,
    private noticeService: NotificationService) {
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public toggleFolder(): void {

    this.isDisplayedForm = !this.isDisplayedForm;

    if (this.isDisplayedForm) {
      this.folderForm = this.buildForm();

    } else {
      this.closeFolder();
    }
  }

  public closeFolder(): void {
    if (this.folderForm) {
      this.folderForm.reset();
    }
    this.isDisplayedForm = false;
  }

  public onSubmit(): void {

    if (this.folderForm.invalid) {

      this.folderForm.markAllAsTouched();

    } else {

      if (this.library.folder_name_pattern && (!this.folderForm.get('name').value)) {
        this.updateFolderName();
      }

      const folderClone = {
        ...this.folderForm.value
      };

      this.transformChoiceFieldsFormat(folderClone);

      this.convertDate(folderClone);

      this.folderApiService.createFolder(folderClone)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe({
          next: (folder: IFolder) => {
            this.closeFolder();
            this.folderCreatedEvent.emit(folder);
            this.noticeService.setNotice('Folder has been successfully created.', 'success');
          },
          error: () => {},
        }
      );
    }
  }

  public changeChoiceBody(idx: string): void {
    if (this.openedChoicetMap.has(idx)) {
      this.openedChoicetMap.clear();
    } else {
      this.openedChoicetMap.clear();
      this.openedChoicetMap.add(idx);
    }
  }

  public setChoiceItem(ctrl: FormControl, item: string): void {
    ctrl.patchValue(item);
    this.openedChoicetMap.clear();
  }

  public trackByFn(index: number): number {
    return index;
  }

  private buildForm(): FormGroup {
    const group = this.fb.group({
      library: [this.library.id],
      name: ['', this.library.folder_name_pattern ? '' : Validators.required],
      index_data: this.fb.group({})
    });

    this.library.index_fields.forEach((field: ILibraryField) => {

      const control = this.fb.control('', field.type === 'date' ? [Validators.required, CustomValidators.dateFormat] : Validators.required);

      (group.get('index_data') as FormGroup).addControl(field.slug, control);
    });
    return group;
  }

  private updateFolderName(): void {
    const patternName = this.generatePatternFolderName(this.folderForm);

    if (patternName) {
      this.folderForm.patchValue({
        name: patternName,
        library: this.library.id
      });
    }
  }

  private transformChoiceFieldsFormat(folderClone: IFolder): void {

    Object.keys(folderClone.index_data).forEach((folderKey: string) => {
        const choiceField = this.library.index_fields.find((indexField: ILibraryField) => {
          return indexField.type === 'choice' && indexField.slug === folderKey;
        });

        if (choiceField) {
          folderClone.index_data[folderKey] = new Array(folderClone.index_data[folderKey]);
        }
      }
    );
  }

  private convertDate(folderForm: IFolder): void {

    this.library.index_fields.forEach((field: ILibraryField) => {
      if (field.type === 'date') {

        folderForm.index_data[field.slug] = changeFormatDate(folderForm.index_data[field.slug]);
      }
    });
  }


  private generatePatternFolderName(folderForm: FormGroup): string {
    let patternName = this.library.folder_name_pattern;

    this.library.index_fields.forEach((field: ILibraryField) => {

      const ctrlValue =  folderForm.controls.index_data.value[field.slug];

      if (ctrlValue && patternName) {
        const searchItem = '{{' + field.name + '}}';

        if (patternName.includes(searchItem)) {
          patternName = patternName.replace(searchItem, ctrlValue);
        }

      } else {
        patternName = '';
      }
    });

    return patternName;
  }
}
