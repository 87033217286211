// ANGULAR
import { Injectable } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

// CORE
import { BaseApiService } from './base-api.service';
import { ILibraryParams, ISeparator } from '@core/interfaces';


@Injectable()
export class SeparatorsApiService extends BaseApiService {

  public getSeparators(queryParams: ILibraryParams): Observable<ISeparator[]> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.get<ISeparator[]>(`${this.apiServerUrl}/api/separators/`, {
      headers,
      params: queryParams as any
    })
    .pipe(
      pluck('results')
    );
  }

  public getSeparatorsByIds(ids: string[]): Observable<ISeparator[]> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    const bodyParams = {separator_list: ids};
    return this.httpClient.post<ISeparator[]>(`${this.apiServerUrl}/api/separators/list/`, bodyParams, {headers});
  }

  public createSeparators(bodyParams: ISeparator): Observable<ISeparator> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post<ISeparator>(`${this.apiServerUrl}/api/separators/`, bodyParams, {headers});
  }

  public deleteSeparator(id: string): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.delete(`${this.apiServerUrl}/api/separators/destroy/${id}/`, {
      headers
    });
  }

  public editSeparator(id: string, bodyParams: ISeparator): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.put(`${this.apiServerUrl}/api/separators/${id}/`, bodyParams, {
      headers
    });
  }
}
