// ANGULAR
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

// CORE
import { BaseApiService } from './base-api.service';
import { ILibrary } from '@core/interfaces';


@Injectable()
export class LibraryApiService extends BaseApiService {

  public createLibrary(bodyParams: ILibrary): Observable<ILibrary> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post<ILibrary>(`${this.apiServerUrl}/api/libraries/`, bodyParams, {headers});
  }

  public getLibraries(): Observable<ILibrary[]> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.get<ILibrary[]>(`${this.apiServerUrl}/api/libraries/`, {headers})
    .pipe(
      pluck('results'),
    );
  }

  public sendFile(folderId: string, libraryId: string, fileData: any): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    const data = new FormData();
    data.append('file', fileData);
    data.append('folder', folderId);
    data.append('library', libraryId);

    return this.httpClient.post(`${this.apiServerUrl}/api/documents/new/`, data, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  public getFiles(bodyParams: any): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    const params = new HttpParams()
    .set('offset', bodyParams.offset)
    .set('limit', bodyParams.limit)
    .set('folder', bodyParams.folder)
    .set('document__name', bodyParams.name)
    .set('ordering', bodyParams.ordering);

    return this.httpClient.get(`${this.apiServerUrl}/api/documents/`, {headers, params});
  }

  public deleteLibrary(id: string): Observable<void> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.delete<void>(`${this.apiServerUrl}/api/libraries/destroy/${id}/`, {
      headers
    });
  }

}
