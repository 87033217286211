import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInRightAnimation =
  trigger('fadeInRight', [
    transition(':enter', [
      style({transform: 'translateX(-100%)', opacity: 0}),
      animate('350ms', style({transform: 'translateX(0)', opacity: 1}))
    ]),
    transition(':leave', [
      style({transform: 'translateX(0)', opacity: 1}),
      animate('350ms', style({transform: 'translateX(-100%)', opacity: 0}))
    ]),
  ]);

export const fadeInRightAnimationMainBatche =
  trigger('fadeInRightWidth', [
    transition(':enter', [
      style({'margin-left': '-318px'}),
      animate('350ms', style({'margin-left': '0px'}))
    ]),
    transition(':leave', [
      style({'margin-left': '0px'}),
      animate('350ms', style({'margin-left': '-318px'}))
    ]),
  ]);

export const fadeInUpAnimation =
  trigger('fadeInUp', [
    transition(':enter', [
      style({opacity: 0}),
      animate('350ms', style({opacity: 1}))
    ]),
    transition(':leave', [
      style({opacity: 1}),
      animate('350ms', style({opacity: 0}))
    ]),
  ]);
