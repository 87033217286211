import { pluck } from 'rxjs/operators';
// ANGULAR
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

// RXJS
import { Observable } from 'rxjs';

// CORE
import { BaseApiService } from './base-api.service';
import { ILogIn } from '@core/interfaces';


@Injectable()
export class UserAuthApiService extends BaseApiService {

  public sendEmail(bodyParams: any): Observable<any> {
    return this.httpClient.post(`${this.apiServerUrl}/api/auth/email-activate/`, bodyParams, {observe: 'response'});
  }

  public createAccount(bodyParams: any): Observable<any> {
    return this.httpClient.post(`${this.apiServerUrl}/api/auth/signup/`, bodyParams, {observe: 'response'});
  }

  public logIn(bodyParams: ILogIn): Observable<any> {
    const headers = {
      'content-type': 'application/x-www-form-urlencoded'
    };

    const params = new HttpParams()
    .set('client_id', bodyParams.client_id)
    .set('grant_type', bodyParams.grant_type)
    .set('username', bodyParams.username)
    .set('password', bodyParams.password);

    return this.httpClient.post(`${this.apiServerUrl}/o/token/`, params, {headers});
  }

  public refreshToken(bodyParams: any): Observable<any> {
    const headers = {
      'content-type': 'application/x-www-form-urlencoded'
    };

    return this.httpClient.post(`${this.apiServerUrl}/o/token/`, bodyParams, {headers});
  }

  public logOut(bodyParams: any): Observable<any> {
    const headers = {
      'content-type': 'application/x-www-form-urlencoded'
    };

    return this.httpClient.post(`${this.apiServerUrl}/o/token/`, bodyParams, {headers});
  }

  public sendEmailChangePass(bodyParams: string): Observable<any> {
    return this.httpClient.post(`${this.apiServerUrl}/api/auth/email-change-password/`, {email: bodyParams});
  }

  public resetPass(bodyParams: object): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post(`${this.apiServerUrl}/api/auth/password-change/`, bodyParams, {headers});
  }

  public getUser(): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.get(`${this.apiServerUrl}/api/users/`, {headers});
  }

  public changePhone(id: string, bodyParams: string): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post(`${this.apiServerUrl}/api/users/${id}/phone-number-change/`, {phone_number: bodyParams}, {headers});
  }

  public changeEmail(id: string, bodyParams: string): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post(`${this.apiServerUrl}/api/users/${id}/email-change/`, {email: bodyParams}, {headers});
  }

  public changeName(id: string, bodyParams: string): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post(`${this.apiServerUrl}/api/users/${id}/name-change/`, {name: bodyParams}, {headers});
  }

  public confirmEmail(bodyParams: any): Observable<any> {
    return this.httpClient
    .post(`${this.apiServerUrl}/api/users/${bodyParams.uid}/email-change-confirm/`, bodyParams, {observe: 'response'});
  }
}
