// ANGULAR
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';

// CURRENT
import { AuthUserService } from './../../auth/services/auth-user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authUserService: AuthUserService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authUserService.isAuthorized) {
      this.router.navigate(['/', 'auth']);
    }

    return !!this.authUserService.isAuthorized;
  }
}
