// ANGULAR
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges,
  EventEmitter, Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';

// RXJS
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  repeatWhen,
  takeUntil, tap
} from 'rxjs/operators';

// CORE
import {
  ILibrary,
  IFolder,
  ISort,
  ILibraryParams
} from '@core/interfaces';

// CURRENT
import { LibrariesService } from './../../../services/libraries.service';
import { FolderApiService } from '@core/services';


@Component({
  selector: 'app-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public library: ILibrary;
  @Output() public updateFolderList = new EventEmitter<object>();

  public folders$ = new Observable<IFolder[]>();
  public folderCreated$ = new ReplaySubject();
  public searchFolderCtrl = new FormControl('');
  public selectedSortField: Map<string, boolean> = new Map<string, boolean>();
  public selectedFolderId: Set<string> = new Set<string>();
  public foldersCount = 0;
  public sortFolderFields: ISort[] = [
    {name: 'Date', key: 'created_timestamp'},
    {name: 'Name', key: 'name'},
  ];

  private destroyed$ = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef,
              private folderApiService: FolderApiService,
              private librariesService: LibrariesService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const prevValue = changes.library.previousValue;
    const currValue = changes.library.currentValue;

    this.library = changes.library.currentValue;

    const libraryParams: ILibraryParams = {
      library: this.library.id,
    };

    this.folders$ = this.folderApiService.getFolders(libraryParams)
    .pipe(
      tap((folderList: IFolder[]) => this.foldersCount = folderList.length),
      repeatWhen(() => this.folderCreated$),
    );

    if (prevValue && currValue.id !== prevValue.id) {
      this.selectedFolderId.clear();
    }
  }

  public ngOnInit(): void {
    this.searchFolderCtrlChanged();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public selectFolderSort(sortItem: ISort): void {
    if (this.selectedSortField.has(sortItem.name)) {
      this.selectedSortField.set(sortItem.name, !this.selectedSortField.get(sortItem.name));
    } else {
      this.selectedSortField.clear();
      this.selectedSortField.set(sortItem.name, false);
    }

    const foldersParams: ILibraryParams = {
      library: this.library.id,
      ordering: this.selectedSortField.get(sortItem.name) ? sortItem.key : `-${sortItem.key}`
    };

    this.folders$ = this.folderApiService.getFolders(foldersParams);

    this.cdRef.markForCheck();
  }

  public onFolderCreated(): void {
    this.updateFolderList.emit();
    this.folderCreated$.next();

    this.cdRef.markForCheck();

  }

  public trackByFn(index: number): number {
    return index;
  }

  private searchFolderCtrlChanged(): void {

    this.searchFolderCtrl.valueChanges
    .pipe(
      debounceTime(250),
      distinctUntilChanged(),
      takeUntil(this.destroyed$),
    ).subscribe((folderName: string) => {

      const libraryParams: ILibraryParams = {
        library: this.library.id,
        name: folderName
      };

      this.folders$ = this.folderApiService.getFolders(libraryParams)
      .pipe(
        tap((folderList: IFolder[]) => this.foldersCount = folderList.length)
      );

      this.cdRef.markForCheck();
    });
  }

  public selectFolder(folderData: IFolder): void {
    this.selectedFolderId.clear();
    this.selectedFolderId.add(folderData.id);
    this.updateFolderList.emit(null);
    this.librariesService.selectedDocument.next(null);
    setTimeout(() => {
      this.updateFolderList.emit(folderData);
    }, 100);
  }
}
