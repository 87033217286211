// ANGULAR
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

// RXJS
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

// CORE
import {IDocument, IListResponse, IListResult} from '@core/interfaces';
import {fadeInRightAnimation} from '@core/animations';
import {FileApiService} from '@core/services';

@Component({
  selector: 'app-preview-doc',
  templateUrl: './preview-doc.component.html',
  styleUrls: ['./preview-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInRightAnimation,
  ],
})
export class PreviewDocComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('batchesList', {static: false, read: ViewContainerRef}) container;
  @Input() public selectedDoc: IDocument;

  public isOpenPreview = false;
  public selectedFolderInTiff = {id: ''};
  public previewIndex = null;
  public menuIndex = null;
  public isOpenMarkSepMenu = false;
  public docMarkedSeparator = [];
  public lists: IListResult[] = [];
  public listIsNext = true;
  public listIsLoading = false;
  public offset = 0;

  private destroyed$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private fileApi: FileApiService
  ) { }

  public ngOnInit(): void {
    this.getLists();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDoc && !changes.selectedDoc.firstChange) {
      this.lists = [];
      this.offset = 0;
      this.getLists();
    }
  }

  public getDocName(name: string): string {
    return `${name.slice(name.lastIndexOf('/') + 1, name.search(/.jpeg/))}`;
  }

  public closeMenu(): void {
    this.menuIndex = null;
  }

  public openPreview(event: any, index: any): void {
    const doc = event.currentTarget.parentElement.parentElement;
    const isSelected = doc.classList.contains('preview');

    if (!isSelected) {
      this.isOpenPreview = true;
      this.previewIndex = index;
    } else {
      this.isOpenPreview = false;
      this.previewIndex = null;
    }

    this.setNavDots(index);
  }

  public closePreview(): void {
    this.isOpenPreview = false;
    this.unselectedPreview();
  }

  public unselectedPreview(): void {
    this.previewIndex = null;
  }

  public sliderPrev(): void {
    const list = document.querySelectorAll('.slider__navigation__links__link');

    if (0 !== this.previewIndex) {
      this.previewIndex--;
    } else {
      this.previewIndex = this.selectedDoc.files_count;
    }

    list[this.previewIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }

  public sliderNext(): void {
    const list = document.querySelectorAll('.slider__navigation__links__link');

    if (this.selectedDoc.files_count !== (this.previewIndex + 1)) {
      this.previewIndex++;
    } else {
      this.previewIndex = 0;
    }

    list[this.previewIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }

  public setNavDots(i: number): void {
    const list = document.querySelectorAll('.slider__navigation__links__link');

    this.previewIndex = i;

    if (list.length) {
      list[this.previewIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }

  public getLists(): void {
    this.listIsLoading = true;

    this.fileApi.getFilesList({offset: this.offset, limit: 20}, this.selectedDoc.id)
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(
        (res: IListResponse) => {
          this.lists = this.lists.concat(res.results);
          this.listIsLoading = false;
          this.offset = this.offset + 20;

          if (!res.next) {
            this.listIsNext = false;
          }

          this.cdRef.markForCheck();
        }
    );
  }
}
