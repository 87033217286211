// ANGULAR
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// CORE
import { LibraryApiService } from '@core/services';
import { IFolder, ISort, IDocument } from '@core/interfaces';

// RXJS
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// CURRENT
import { LibrariesService } from '../../../services';


@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent implements OnInit, OnDestroy {

  @Input() public selectedFolder: IFolder;

  public selectedSortField: Map<string, boolean> = new Map<string, boolean>();
  public foldersParams = {ordering: '-created_timestamp'};
  public searchFilesCtrl = new FormControl('');

  public offset = 0;
  public limit = 20;
  public next = true;
  public sortFolderFields: ISort[] = [
    {name: 'Date', key: 'created_timestamp'},
    {name: 'Name', key: 'document__name'},
  ];
  public searchName = '';

  public filesList = [];
  public selectedDoc: IDocument;
  public openedMenuMap: Set<string> = new Set<string>();

  private destroyed$ = new Subject<void>();

  constructor(
    public libraryApiService: LibraryApiService,
    private cdRef: ChangeDetectorRef,
    private librariesService: LibrariesService
  ) {
  }

  public ngOnInit(): void {
    this.selectedSortField.set('Date', false);
    this.getFiles();
    this.searchFilesCtrlChanged();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getFiles(): void {
    const data = {
      offset: this.offset,
      limit: this.limit,
      folder: this.selectedFolder.id,
      ordering: this.foldersParams.ordering,
      name: this.searchName
    };

    this.libraryApiService.getFiles(data)
    .pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      res => {
        if (res.next === null) {
          this.next = false;
        }
        this.filesList = this.filesList.concat(res.results);
        this.cdRef.markForCheck();
      }
    );
  }

  private searchFilesCtrlChanged(): void {
    this.searchFilesCtrl.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe((filesName: string) => {
      this.searchName = filesName;
      this.offset = 0;
      this.filesList = [];
      this.getFiles();
    });
  }

  public selectFolderSort(sortItem: ISort): void {
    if (this.selectedSortField.has(sortItem.name)) {
      this.selectedSortField.set(sortItem.name, !this.selectedSortField.get(sortItem.name));
    } else {
      this.selectedSortField.clear();
      this.selectedSortField.set(sortItem.name, false);
    }

    this.foldersParams = {
      ordering: this.selectedSortField.get(sortItem.name) ? sortItem.key : `-${sortItem.key}`
    };

    this.offset = 0;
    this.filesList = [];

    this.getFiles();
  }

  public scrollFilesList(event: any): void {
    const el = event.target;
    if (el.scrollHeight > 0 && el.scrollHeight === (el.scrollTop + el.offsetHeight)) {
      this.offset = this.offset + 20;
      this.getFiles();
    }
  }

  public filesType(file: any): string {
    const type = file.file.slice(file.file.lastIndexOf('.') + 1, file.file.length);

    return type;
  }

  public searchFile(): void {
    this.offset = 0;
    this.filesList = [];
    this.getFiles();
  }

  public selectDoc(idx: number, event: Event): void {
    this.selectedDoc = this.filesList[idx];
    this.librariesService.selectedDocument.next(this.filesList[idx]);
    this.openedMenuMap.clear();
    event.stopPropagation();
  }

  public openMenu(event: Event, id: string): void {
    this.openedMenuMap.clear();
    this.openedMenuMap.add(id);
    event.stopPropagation();
  }

  public closeMenu(event: Event): void {
    this.openedMenuMap.clear();
    event && event.stopPropagation();
  }
}
