// ANGULAR
import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// CORE
import { ParamInterceptor } from './core/interceptors/api.interceptor';
import { AuthGuard } from './core/guards';
import { UserAuthApiService, NotificationService } from '@core/services';

// MAIN
import {
  PrintLayoutComponent,
  PrintSeparatorsComponent,
  PrintDocTypesComponent,
} from './main/components';

// CURRENT
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthUserService } from './auth/services/auth-user.service';
import { NotificationComponent } from './notification/notification.component';


@NgModule({
  declarations: [
    AppComponent,
    PrintLayoutComponent,
    PrintSeparatorsComponent,
    PrintDocTypesComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthGuard,
    UserAuthApiService,
    NotificationComponent,
    NotificationService,
    AuthUserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParamInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
}
