// ANGULAR
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

// RXJS
import { repeatWhen, takeUntil, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';

// CORE
import { IDocType, ILibrary, ILibraryParams } from '@core/interfaces';
import { DocumentTypesApiService } from '@core/services';

// CURRENT
import { PrintService } from '../../../../services';

@Component({
  selector: 'app-doc-types',
  templateUrl: './doc-types.component.html',
  styleUrls: ['./doc-types.component.scss']
})
export class DocTypesComponent implements OnInit, OnDestroy {

  @Input() public library: ILibrary;

  public documentTypesName = new FormControl('', Validators.required);

  public docTypes$ = new Observable<IDocType[]>();
  public docTypeCount = 0;
  public updateList$ = new ReplaySubject();

  public selectedDocType: IDocType;
  public isEditing = false;
  public isCreating = false;

  public printDocTypeMap: Set<string> = new Set<string>();

  private libraryParams: ILibraryParams;
  private destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private documentTypesApiService: DocumentTypesApiService,
              private printService: PrintService) {
  }

  public ngOnInit(): void {
    this.libraryParams = {
      library: this.library.id,
    };

    this.docTypesList();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public toggleForm(): void {
    this.isCreating = !this.isCreating;
    this.isEditing = false;
    this.documentTypesName.reset();
  }

  public selectDocType(docType: IDocType): void {
    this.selectedDocType = docType;
  }

  public edit(docType: IDocType): void {

    this.documentTypesName.reset();
    this.documentTypesName.setValue(docType.document_types_name);

    this.selectedDocType = docType;
    this.isEditing = true;
  }

  public addToPrint(id: string): void {
    if (this.printDocTypeMap.has(id)) {
      this.printDocTypeMap.delete(id);
    } else {
      this.printDocTypeMap.add(id);
    }
  }

  public printDocTypes(DocTypeMap: Set<string> = new Set()): void {
    const docTypesIds = [...DocTypeMap.values()];

    this.print(docTypesIds);
  }


  public printSelectedDocType(event: Event, docTypeId: string): void {
    this.print([docTypeId]);

    event.stopPropagation();
  }


  public print(docTypesIds: string[] = []): void {

    const libraryParams = {
      library: this.library.id,
    };

    this.printService.printDocument('doctypes', docTypesIds, libraryParams);
  }


  public onSubmit(): void {

    if (this.documentTypesName.invalid) {

      this.documentTypesName.markAsTouched();
    } else {

      if (this.isCreating) {

        this.createDocType();
      } else {

        this.editСurrentDocType(this.documentTypesName.value);
      }
    }
  }

  public delete(id: string): void {
    this.documentTypesApiService.deleteDocType(id)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {
      this.updateList$.next();
    });
  }

  public trackByFn(index: number): number {
    return index;
  }

  private cleanForm(): void {
    this.isCreating = false;
    this.isEditing = false;

    this.selectedDocType = null;

    this.documentTypesName.reset();
  }

  private docTypesList(): void {
    this.docTypes$ = this.documentTypesApiService.getDocTypes(this.libraryParams)
    .pipe(
      tap((docTypeList: IDocType[]) => this.docTypeCount = docTypeList.length),
      repeatWhen(() => this.updateList$),
    );
  }

  private createDocType(): void {

    const bodyParams = {document_types_name: this.documentTypesName.value, library: this.library.id};

    this.documentTypesApiService.createDocType(bodyParams)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
        next: () => {
          this.updateList$.next();
          this.cleanForm();
        },
        error: () => {
        },
      }
    );
  }

  private editСurrentDocType(docName: string): void {

    const bodyParams = {
      document_types_name: docName
    };

    this.documentTypesApiService.editDocType(this.selectedDocType.id, bodyParams)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
        next: () => {
          this.updateList$.next();
          this.cleanForm();
        },
        error: () => {
        },
      }
    );
  }
}
