// CORE
import { NotificationService } from '@core/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { INotice } from '@core/interfaces';

// RXJS
import { Subject, interval } from 'rxjs';
import { filter, tap, audit, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  public textNotice: string;
  public typeNotice: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private noticeService: NotificationService
  ) {
  }

  public ngOnInit(): void {
    this.open();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public open(): void {
    this.noticeService.notice
    .pipe(
      filter((value: INotice) => value && !!value.text),
      tap((value: INotice) => {
        this.textNotice = value.text;
        this.typeNotice = value.type;
      }),
      audit( value => interval(value.duration)),
      tap(() => {
        this.noticeService.close();
      }),
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {});
  }
}
