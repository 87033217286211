// ANGULAR
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';

// CORE
import { IFolder } from '@core/interfaces';
import { FolderApiService } from '@core/services';

// CURRENT
import { BatcheService } from './../../../../services/batche.service';

@Component({
  selector: 'app-mark-separator',
  templateUrl: './mark-separator.component.html',
  styleUrls: ['./mark-separator.component.scss']
})
export class MarkSeparatorComponent implements OnInit {

  @Output() public closeMarkSeparatorCon = new EventEmitter<void>();
  @Output() public closeMenu = new EventEmitter<void>();

  public folders$ = new Observable<IFolder[]>();
  public isOpenFolderList = false;
  public errors = [];
  public form: FormGroup;
  public selectedMarkFolder: IFolder;

  constructor(
    private fb: FormBuilder,
    private folderApi: FolderApiService,
    private batcheService: BatcheService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.fb.group({
      folderName: ['', [Validators.required]],
    });
  }

  public toggleFolderList(event: Event): void {
    if (!this.isOpenFolderList) {
      this.isOpenFolderList = true;

      this.folders$ = this.folderApi.getFolders({library: this.batcheService.selectedLibrary.id});
    } else {
      this.isOpenFolderList = false;
    }
    event.stopPropagation();
  }

  public selectMarkFolder(data: IFolder): void {
    this.selectedMarkFolder = data;
  }

  public markSeparator(event: Event): void {
    const folderName = this.form.controls.folderName;
    folderName.markAsDirty();
    if (folderName.value && this.selectedMarkFolder.id) {
      const url = this.batcheService.selectedFolderInTiff.files[this.batcheService.menuIndex].slice(
        this.batcheService.selectedFolderInTiff.files[this.batcheService.menuIndex].search(/tiff/g),
        this.batcheService.selectedFolderInTiff.files[this.batcheService.menuIndex].search(/\?AWS/g)
      );
      const data = {
        separators: [
          {
            document_name: folderName.value,
            files: [],
            folder: this.selectedMarkFolder.id,
            image_url: url
          }
        ]
      };

      this.batcheService.docMarkedSeparator.push(this.batcheService.menuIndex);
      this.batcheService.docMarkedSeparatorData.separators.push(data.separators[0]);
      this.batcheService.parsedTiff.parsed_documents.push(data.separators[0]);
      this.closeMenuMarkSeparator();
      this.closeMenu.emit();
      event.stopPropagation();
    } else {
      if (!this.selectedMarkFolder.id) {
        this.errors.push('folderForMark');
      }

      if (!folderName.value) {
        this.errors.push('name');
      }

      setTimeout(() => {
        this.errors.splice(0, this.errors.length);
      }, 3000);
    }
  }

  public closeMenuMarkSeparator(): void {
    this.closeMarkSeparatorCon.emit();
    this.isOpenFolderList = false;
    this.form.setValue({folderName: ''});
    this.form.reset(this.form.value);
    this.selectedMarkFolder = {name: '', id: '', documents_count: null};
  }

}
