// ANGULAR
import {AbstractControl} from '@angular/forms';

// CORE
import {DATE_FORMAT} from '@core/utils';

// CURRENT
import * as moment from 'moment';

export function dateValidator(control: AbstractControl): {[key: string]: boolean} | null {
  const date = control.value;
  const isValid = moment(date, DATE_FORMAT, true).isValid();

  return isValid ? null : {dateNotValid: true};
}
