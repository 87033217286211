// ANGULAR
import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';

// RXJS
import { Observable } from 'rxjs';

// CORE
import {IParsedTiffFile, ITiffData, IGetLoadedTiff, ITiffUrlParams} from '@core/interfaces';
import { BaseApiService } from './base-api.service';


@Injectable()
export class BatchesApiService extends BaseApiService {

  public getTiffs(urlParams: ITiffUrlParams): Observable<IGetLoadedTiff> {
    const headers = {
      Authorization: this.getToken(),
    };

    return this.httpClient.get<IGetLoadedTiff>(`${this.apiServerUrl}/api/tiffiles/`, {headers, params: urlParams as any});
  }

  public sendTiffFile(bodyParams: any, library: string, name: string): Observable<object> {
    const headers = {
      Authorization: this.getToken(),
    };

    const data = new FormData();
    data.append('file', bodyParams);
    data.append('library', library);
    data.append('name', name);

    return this.httpClient.post(`${this.apiServerUrl}/api/tiffiles/`, data, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  public getTiffData(bodyParams: string): Observable<HttpResponse<IParsedTiffFile>> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.get<IParsedTiffFile>(`${this.apiServerUrl}/api/tiffiles/${bodyParams}/`, {
      headers,
      observe: 'response'
    });
  }

  public confirmTiffData(bodyParams: ITiffData, loadFile): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post(`${this.apiServerUrl}/api/tiffiles/${bodyParams.id}/confirm/`, {}, {headers});
  }

  public editTiff(bodyParams: object, id: string): Observable<any> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post(`${this.apiServerUrl}/api/parsed-documents/${id}/split/`, bodyParams, {headers});
  }
}
