// ANGULAR
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// CORE
import { AuthGuard } from './core/guards';
import {
  PrintLayoutComponent,
  PrintSeparatorsComponent
} from './main/components';
import { PrintDocTypesComponent } from './main/components/print-layout/components/doc-types/print-doc-types.component';

const routes: Routes = [

  {
    path: 'auth',
    children: [
      {
        path: '',
        loadChildren: './auth/auth.module#AuthModule'
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './main/main.module#MainModule'
      },
    ]
  },
  {
    path: 'print', outlet: 'print', component: PrintLayoutComponent,
    children: [
      {
        path: 'separators/:separatorIds',
        component: PrintSeparatorsComponent
      },
      {
        path: 'doctypes/:doctypesIds',
        component: PrintDocTypesComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule {
}
