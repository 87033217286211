// ANGULAR
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

// RXJS
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { repeatWhen, takeUntil, } from 'rxjs/operators';

// CORE
import { LibraryApiService } from '@core/services';
import { ILibrary, IDocument } from '@core/interfaces';
import { fadeInRightAnimation } from '@core/animations';

// CURRENT
import { LibrariesService } from '../../../services';
import { MANAGE_LIBRARY_OPTIONS } from '../../../../core/lib';


@Component({
  selector: 'app-library-list',
  templateUrl: './library-list.component.html',
  styleUrls: ['./library-list.component.scss'],
  animations: [
    fadeInRightAnimation,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LibraryListComponent implements OnInit, OnDestroy {

  public libraries$ = new Observable<ILibrary[]>();
  public updateLibraryList$ = new ReplaySubject();
  public isOpenModal = false;

  public selectedLibraryMap: Set<string> = new Set<string>();
  public manageLibraryMap: Set<string> = new Set<string>();
  public selectedLibrary: ILibrary;
  public managedLibrary: ILibrary;
  public manageLibraryOptions = MANAGE_LIBRARY_OPTIONS;
  public selectedManageOption: string;

  public selectedFolder: object;
  public selectedDoc: IDocument;

  private destroyed$ = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef,
              private libraryApiService: LibraryApiService,
              private librariesService: LibrariesService) {
  }

  public ngOnInit(): void {
    this.libraries$ = this.libraryApiService.getLibraries()
    .pipe(
      repeatWhen(() => this.updateLibraryList$),
    );

    this.librariesService.selectedDocument
    .pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (doc: IDocument) => {
        this.selectedDoc = doc;
      }
    );
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public selectLibrary(library: ILibrary): void {

    this.selectedLibraryMap.clear();
    this.selectedLibraryMap.add(library.id);
    this.selectedLibrary = library;
    this.selectedFolder = null;

    this.cdRef.markForCheck();
  }

  public saveDataFolder(folder: object): void {
    this.selectedFolder = folder;
    // this.updateLibraryList$.next();
  }

  public deleteLibrary(id: string): void {
    this.libraryApiService.deleteLibrary(id)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
      next: () => {
        this.updateLibraryList$.next();
      },
      error: () => {
      }
    });
  }

  public manageLibrary(event: Event, library: ILibrary): void {
    this.manageLibraryMap.clear();
    this.manageLibraryMap.add(library.id);

    this.managedLibrary = library;

    event.stopPropagation();
  }

  public closeManageLibrary(event: Event): void {
    this.manageLibraryMap.clear();
    event.stopPropagation();
  }

  public toggleManageLIbrary(event?: Event, option?: string): void {

    this.manageLibraryMap.clear();
    if (event) {
      this.isOpenModal = true;
      this.selectedManageOption = option;
      event.stopPropagation();
    }
  }

  public onLibraryCreated(): void {
    this.updateLibraryList$.next();
  }

  public onManageFinishedEvent(): void {
    this.isOpenModal = false;
  }

  public unselectLibrary(): void {
    this.selectedLibraryMap.clear();
    this.selectedLibrary = null;
    this.selectedFolder = null;
    this.selectedDoc = null;
  }

  public trackByFn(index: number): number {
    return index;
  }
}
