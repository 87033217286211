// ANGULAR
import { Component } from '@angular/core';

// CURRENT
import { BatcheService } from './../../../../services/batche.service';
import {ISelectedFolderInTiff} from '@core/interfaces';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {

  constructor(
    private batcheService: BatcheService,
  ) { }

  public get selectedFolderInTiff(): ISelectedFolderInTiff {
    return this.batcheService.selectedFolderInTiff;
  }

  public get previewIndex(): number {
    return this.batcheService.previewIndex;
  }

  public closePreview(): void {
    this.batcheService.closePreview();
  }

  public sliderPrev(): void {
    const list = document.querySelectorAll('.slider__navigation__links__link');

    if (0 !== this.batcheService.previewIndex) {
      this.batcheService.previewIndex--;
    } else {
      this.batcheService.previewIndex = this.batcheService.selectedFolderInTiff.files.length - 1;
    }

    list[this.batcheService.previewIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }

  public sliderNext(): void {
    const list = document.querySelectorAll('.slider__navigation__links__link');

    if (this.batcheService.selectedFolderInTiff.files.length !== (this.batcheService.previewIndex + 1)) {
      this.batcheService.previewIndex++;
    } else {
      this.batcheService.previewIndex = 0;
    }

    list[this.batcheService.previewIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }

  public setNavDots(i: number): void {
    const list = document.querySelectorAll('.slider__navigation__links__link');

    this.batcheService.previewIndex = i;

    list[this.batcheService.previewIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }
}
