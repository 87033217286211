// ANGULAR
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

// RXJS
import {Observable, Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// CORE
import {
  ILibrary,
  ILoadedTiff,
  ILoadingFile,
  ISelectedFolderInTiff,
} from '@core/interfaces';

import {
  fadeInRightAnimation,
  fadeInRightAnimationMainBatche
} from '@core/animations';

import {
  LibraryApiService,
} from '@core/services';

// CURRENT
import { DownloadBatchesComponent } from './components';
import { SendTiff } from '../../services';
import { BatcheService } from '../../services';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss'],
  animations: [
    fadeInRightAnimation,
    fadeInRightAnimationMainBatche
  ],
})
export class BatchesComponent implements OnInit, OnDestroy {

  @ViewChild('batchesList', {static: false, read: ViewContainerRef})
  public container: ViewContainerRef;

  public startBtnClass = 'button';
  public downloadCont = false;
  public isDocListOpen = false;
  public libraries$ = new Observable<ILibrary[]>();
  public sendTiffData: ILoadingFile[];
  public selectedLibrary: ILibrary;
  public selectedFolderInTiff: ISelectedFolderInTiff = {files: [], id: ''};

  private destroyed$ = new Subject<void>();

  constructor(
    private resolver: ComponentFactoryResolver,
    private libraryApiService: LibraryApiService,
    private sendTiffService: SendTiff,
    private batcheService: BatcheService,
  ) {
  }

  public ngOnInit(): void {
    this.getTiffsData();
    this.getLibraries();

    if (this.sendingTiff[0]) {
      this.openBatchingList();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.batcheService.closeManageFile();
    this.resetTiffParams();
  }

  public get showImgList(): boolean {
    return this.batcheService.showImgList;
  }

  public get docListIsOpen(): boolean {
    return this.batcheService.docListIsOpen;
  }

  public get previewIsOpen(): boolean {
    return this.batcheService.previewIsOpen;
  }

  private get tiffsIsLoading(): boolean {
    return this.sendTiffService.tiffsIsLoading;
  }

  private get getTiffsNext(): boolean {
    return this.sendTiffService.getTiffsNext;
  }

  public get getTiffs(): ILoadedTiff[] {
    return this.sendTiffService.tiffs;
  }

  public getTiffsData(): void {
    if (this.getTiffsNext) {
      this.sendTiffService.getTiffs();
    }
  }

  private resetTiffParams(): void {
    this.sendTiffService.tiffsOffset = 0;
    this.sendTiffService.getTiffsNext = true;
    this.sendTiffService.tiffs = [];
  }

  private getLibraries(): void {
    this.libraries$ = this.libraryApiService.getLibraries();
  }

  private get sendingTiff(): ILoadingFile[] {
    return this.sendTiffService.getSendingTiff();
  }

  private openSendingTiffsList(): void {
    if (this.sendTiffService.getSendingTiff().length) {
      this.sendTiffData = this.sendingTiff;
    } else {
      this.createComponentAction();
    }
  }

  private createComponentAction(): void {
    timer(500)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
          this.createComponent();
        }
      );
  }

  public openBatchingList(): void {
    if (!this.downloadCont) {
      this.openSendingTiffsList();
    }
    this.startBtnClass = (this.startBtnClass.indexOf('active') !== -1) ? 'button' : 'button active';
    this.downloadCont = !this.downloadCont;
    this.batcheService.closeManageFile();
  }

  public createComponent(): void {
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(DownloadBatchesComponent);
    const component: ComponentRef<DownloadBatchesComponent> = this.container.createComponent(factory);
    component.instance.createComp.pipe(takeUntil(this.destroyed$)).subscribe(() => this.createComponent());
    component.instance.destroyComp.pipe(takeUntil(this.destroyed$)).subscribe(() => component.destroy());
  }

  public selectLibrary(data): void {
    this.selectedLibrary = data;
  }

  public trackByFn(index: number, batche: any): number {
    return batche ? batche.id : null;
  }
}
