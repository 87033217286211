// ANGULAR
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

// CORE
import { ILibrary } from '@core/interfaces';
import { MANAGE_LIBRARY_OPTIONS } from '../../../core/lib';

@Component({
  selector: 'app-manage-library',
  templateUrl: './manage-library.component.html',
  styleUrls: ['./manage-library.component.scss']
})
export class ManageLibraryComponent {

  @Input() public library: ILibrary;
  @Input() public manageOption: string;
  @Output() public manageFinishedEvent = new EventEmitter<void>();

  public manageLibraryOptions = MANAGE_LIBRARY_OPTIONS;

  public closeModal(): void {
    this.manageFinishedEvent.emit();
  }

  changeManageOption(option: string) {
    this.manageOption = option;
  }
}
