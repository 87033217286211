// ANGULAR
import { Injectable, OnDestroy } from '@angular/core';

// RXJS
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// CORE
import { IFolder, IFile, ILoadingFile } from '@core/interfaces';
import { LibraryApiService, NotificationService } from '@core/services';

@Injectable({
  providedIn: 'root'
})

export class SendFile implements OnDestroy {

  public sendFile$ = new BehaviorSubject<Map<number, ILoadingFile>>(new Map<0, ILoadingFile>());

  private destroyed$ = new Subject<void>();

  constructor(
    public libraryApiService: LibraryApiService,
    private noticeService: NotificationService
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public loadFile(folderData: IFolder, fileData: IFile, componentIdx: number): void {
    this.libraryApiService.sendFile(folderData.id, folderData.library, fileData)
    .pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (res: ILoadingFile) => {
        const newData = this.sendFile$.value;

        newData.set( componentIdx, res);
        this.sendFile$.next(newData);

        if (res.type === 3) {
          this.noticeService.setNotice('File has been successfully uploaded.', 'success');
        }
      }
    );
  }
}
