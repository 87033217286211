// ANGULAR
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';

// RXJS
import {takeUntil, finalize} from 'rxjs/operators';
import {Subject, BehaviorSubject, interval} from 'rxjs';

// CORE
import { UserAuthApiService } from '@core/services';
import { IToken, ICreateAccountParams } from '@core/interfaces';
import { getHttpErrorHelper } from '@core/helpers';

// CURRENT
import { environment } from '@environments/environment';

@Injectable()
export class AuthUserService implements OnDestroy {

  public email: string;
  public errorText = new BehaviorSubject<string>(null);
  public signInCardIsOpen = false;
  public forgotPassCardIsOpen = false;
  public sendEmailCardIsOpen = false;
  public emailIsSent = false;
  public resetEmailIsSent = false;
  public textError: string = '';
  public requestIsSent = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userApi: UserAuthApiService,
  ) { }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get isAuthorized(): boolean {
    return !!localStorage.getItem('access_token');
  }

  public logIn(pass: string, email?: string): void {
    this.userApi.logIn({
        client_id: environment.client_id,
        grant_type: 'password',
        username: email || this.email,
        password: pass
      }
    )
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (loginRes: IToken) => {
        localStorage.removeItem('email');
        localStorage.setItem('access_token', loginRes.access_token);
        localStorage.setItem('refresh_token', loginRes.refresh_token);
        window.location.replace('/');
        this.clearTextError();
      },
      error => {
        this.textError = 'Oops. Seems like your e-mail or password are incorrect.';
      }
    );
  }

  public refreshToken(): void {
    this.requestIsSent = true;

    const params = new HttpParams()
      .set('client_id', environment.client_id)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', localStorage.getItem('refresh_token'));

    this.userApi.refreshToken(params)
      .subscribe(
      data => {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        document.location.reload();
        this.requestIsSent = false;
      },
      tokenError => {
        if (!tokenError.ok) {
          localStorage.setItem('access_token', '');
          localStorage.setItem('refresh_token', '');
          this.router.navigate(['/', 'auth']);
        }
      }
    );
  }

  public creaetAccount(form: ICreateAccountParams) {
    this.userApi.createAccount({...form})
    .pipe(
      finalize(() => this.clearTextError()),
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (data: {email: string}) => {
        if (data) {
          this.errorText.next(null);

          this.logIn(form.password, localStorage.getItem('email'));
        }
      },
      (error: HttpErrorResponse) => {
        const textError = getHttpErrorHelper(error.error);

        this.errorText.next(textError);
      }
    );
  }

  public sendResetPass(): void {
    this.userApi.sendEmailChangePass(this.email)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        this.resetEmailIsSent = true;
      }
    );
  }

  public resetPass(form: ICreateAccountParams): void {
    this.userApi.resetPass(form)
    .pipe(
      finalize(() => this.clearTextError()),
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (res: {confirmed: boolean}) => {
        if (res.confirmed) {
          this.router.navigate(['/auth'], {queryParams: {confirm: true}});
        }
      },
      (error: HttpErrorResponse) => {
        const textError =  error.error.password ? error.error.password.join(' ') : error.error.non_field_errors.join(' ');

        this.errorText.next(textError);
      }
    );
  }

  public sendEmail(email: string): void {
    this.userApi.sendEmail({email})
    .pipe(
      finalize(() => this.clearTextError()),
      takeUntil(this.destroyed$)
    )
    .subscribe({
      next: (res) => {
        this.emailIsSent = true;
      },
      error: (error) => {
        if (!error.ok) {
          this.textError = error.error.email;
        }
      }

    });
  }

  private clearTextError(): void {
    interval(3000)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
        this.textError = null;
      }
    );
  }

  public confirmEmail(params): void {
    this.userApi.confirmEmail(params)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        this.email = res.email;
      },
      error => {
        this.textError = 'Token invalid';
    });
  }
}
