// ANGULAR
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// CORE
import { DocumentTypesApiService } from '@core/services';
import { IDocType, ILibraryParams, ISeparator } from '@core/interfaces';

// CURRENT
import { PrintService } from '../../../../services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-print-doc-types',
  templateUrl: './print-doc-types.component.html',
  styleUrls: ['./print-doc-types.component.scss'],
  providers: [DocumentTypesApiService],
})

export class PrintDocTypesComponent implements OnInit, OnDestroy {

  public docTypesIds = [];
  public docTypesList: IDocType[];

  private destroyed$ = new Subject<void>();

  public library: ILibraryParams;

  constructor(private route: ActivatedRoute,
              private printService: PrintService,
              private documentTypesApiService: DocumentTypesApiService) {
  }

  public ngOnInit(): void {

    if (this.route.snapshot.params.doctypesIds) {
      this.docTypesIds = this.route.snapshot.params.doctypesIds
      .split(',');
    }

    this.library = {
      library: this.route.snapshot.queryParamMap.get('library')
    };

    this.print();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  
  private print(): void {

    if (this.docTypesIds.length) {
      this.printDocTypesByIds();
    } else {
      this.printAllDocTypes();
    }
  }


  private printDocTypesByIds(): void {
    this.documentTypesApiService.getDocTypesByIds(this.docTypesIds)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe((docTypes: IDocType[]) => {
      this.docTypesList = docTypes;
      this.printService.onDataReady();
    });
  }

  private printAllDocTypes(): void {
    this.documentTypesApiService.getDocTypes(this.library)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe((docTypes: IDocType[]) => {
      this.docTypesList = docTypes;
      this.printService.onDataReady();
    });
  }
}
