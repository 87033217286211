import { BehaviorSubject } from 'rxjs';
import { INotice } from '../interfaces/notice.interface';
import { Injectable } from '@angular/core';

@Injectable()

export class NotificationService {

  public notice = new BehaviorSubject<INotice>({text: null, type: null, duration: null});

  private isOpen: boolean = false;

  public get isOpened(): boolean {
    return this.isOpen;
  }

  public setNotice(noticeText: string, noticeType: string, duration?: number): void {
    this.isOpen = true;

    this.notice.next({text: noticeText, type: noticeType, duration: duration ? duration : 3000});
  }

  public close(): void {
    this.isOpen = false;
  }
}
