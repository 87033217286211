// ANGULAR
import { Component } from '@angular/core';

// CORE
import {IParsedTiffFile, ISelectedFolderInTiff, ITiffParsedDoc} from '@core/interfaces';

// CURRENT
import { BatcheService } from './../../../../services/batche.service';


@Component({
  selector: 'app-docs-list',
  templateUrl: './docs-list.component.html',
  styleUrls: ['./docs-list.component.scss']
})
export class DocsListComponent {

  constructor(
    private batcheService: BatcheService,
  ) { }

  public get parsedTiff(): IParsedTiffFile {
    return this.batcheService.parsedTiff;
  }

  public get selectedFolderInTiff(): ISelectedFolderInTiff {
    return this.batcheService.selectedFolderInTiff;
  }

  public selectFolder(folderData: any): void {
    this.batcheService.selectedFolderInTiff = folderData;
    this.batcheService.showImgList = true;
    this.batcheService.unselectedPreview();
    this.batcheService.previewIsOpen = false;
  }

  public getTotalFolders(data: ITiffParsedDoc[]): number {
    const uniqueFolders = [];
    data.forEach( doc => {
      if (!uniqueFolders.includes(doc.folder)) {
        uniqueFolders.push(doc.folder);
      }
    });

    return uniqueFolders.length;
  }
}
