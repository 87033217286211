// ANGULAR
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';

// CORE
import { UserAuthApiService } from '@core/services';
import {SendTiff} from '../../../services';
import {fadeInUpAnimation} from '@core/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fadeInUpAnimation
  ]
})
export class HeaderComponent implements OnInit {

  public userName: string;
  public form: FormGroup;

  public isNameEditing = false;
  public isEmailEditing = false;
  public isPhoneEditing = false;
  public isProfileOpen = false;
  public isFormChanged = false;
  public user = {name: '', email: '', phone_number: '', id: ''};
  public newUser = {name: '', email: '', phone_number: '', id: ''};
  public notificationIsOpen = false;

  public list = [
    {type: 'deleted', deleted_elem: 'library', deleted_name: 'Test lib 1'},
    {type: 'shared', rights: 'write', user: 'Kirill', user_email: 'kirillsinkevich@yandex.ru'},
    {type: 'loaded', folder: 'folder 3'},
    {type: 'deleted', deleted_elem: 'folder', deleted_name: 'folder 2'},
    {type: 'parsed', library: 'Test'},
    {type: 'published', doc_count: 14420},
    {type: 'deleted', deleted_elem: 'file', deleted_name: 'Test file 1'},
    {type: 'loaded', folder: 'folder 1'},
    {type: 'shared', rights: 'write', user: 'Admin', user_email: 'admin@yandex.ru'},
    {type: 'parsed', library: '2020-0001-Savin'},
    {type: 'deleted', deleted_elem: 'folder', deleted_name: 'Folder 1'},
    {type: 'loaded', folder: 'test folder'},
    {type: 'shared', rights: 'write', user: 'Kirill', user_email: 'kirillsinkevich@yandex.ru'},
    {type: 'published', doc_count: 410},
    {type: 'deleted', deleted_elem: 'library', deleted_name: 'Test lib 3'},
    {type: 'deleted', deleted_elem: 'file', deleted_name: 'Tiff file'},
    {type: 'parsed', library: 'Test-lib 1'},
    {type: 'published', doc_count: 140},
    {type: 'loaded', folder: 'test folder'},
  ];

  constructor(
    private fb: FormBuilder,
    private userApi: UserAuthApiService,
    private sentTiffService: SendTiff
  ) {
  }

  public ngOnInit(): void {
    this.getUser();
    this.getUserData();
    this.initForm();
  }

  public get loadingIsDone(): boolean {
    return this.sentTiffService.loadingIsDoneInHeader;
  }

  public get loadingProgress(): number {
    return this.sentTiffService.loadingProgressInHeader;
  }

  public initForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
    }, {validator: this.form});
  }

  public toggleProfile(): void {
    this.isProfileOpen = !this.isProfileOpen;
    if (this.isProfileOpen) {
      this.getUserData();
    }
  }

  public closeProfile(): void {
    this.isProfileOpen = false;
  }

  public logOut(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    location.reload();
  }

  public getUserData(): void {
    this.userApi.getUser().subscribe(
      res => {
        this.user = res;
      }
    );
  }

  public editStatus(param: string, event: any): void {
    this.unselected();
    this[param] = !this[param];

    if (event) {
      event.currentTarget.parentElement.childNodes.forEach(element => {
        if (element.classList.contains('profile-field__form__data__text')) {
          element.focus();
        }
      });
    }
  }

  public unselected(): void {
    this.isNameEditing = false;
    this.isEmailEditing = false;
    this.isPhoneEditing = false;
  }

  public changeField(event: any, param: string): void {
    this.newUser[param] = event.currentTarget.value;
  }

  public keydownListener(event: any): void {
    if (event.code === 'Enter') {
      this.done();
    }
  }

  public done(): void {
    this.unselected();
    this.form.markAsPristine();
    this.isFormChanged = true;
  }

  public saveChanges(): void {

    if (this.newUser.name.length > 0 && this.user.name !== this.newUser.name) {
      this.userApi.changeName(this.user.id, this.newUser.name).subscribe();
    }

    if (this.newUser.email.length > 0 && this.user.email !== this.newUser.email && !this.form.controls.email.invalid) {
      this.userApi.changeEmail(this.user.id, this.newUser.email).subscribe();
    }

    if (this.newUser.phone_number.length > 0 && this.user.phone_number !== this.newUser.phone_number) {
      this.userApi.changePhone(this.user.id, this.newUser.phone_number).subscribe();
    }

    this.isFormChanged = false;
    this.form.markAsUntouched();
  }

  public getUser(): void {
    this.userApi.getUser().subscribe(
      res => {
        this.userName = res.name.slice(0, 1);
      }
    );
  }

  public toggleNotification(): void {
    this.notificationIsOpen = !this.notificationIsOpen;
  }

  public closeNotification(): void {
    this.notificationIsOpen = false;
  }
}
