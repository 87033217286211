// ANGULAR
import { Injectable } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

// CORE
import { BaseApiService } from './base-api.service';
import { IShare } from '@core/interfaces';


@Injectable()
export class ShareApiService extends BaseApiService {

  public createSharing(bodyParams: IShare): Observable<IShare> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post<IShare>(`${this.apiServerUrl}/api/sharing/create/`, bodyParams, {headers});
  }

  public shareList(libraryId: string): Observable<IShare[]> {
    const headers = {
      Authorization: this.getToken()
    };

    const queryParams = {
      library: libraryId
    };

    return this.httpClient.get<IShare[]>(`${this.apiServerUrl}/api/sharing/list/`, {headers, params: queryParams as any})
    .pipe(
      pluck('results'),
    );
  }

  public deleteSharing(id: string): Observable<void> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.delete<void>(`${this.apiServerUrl}/api/sharing/delete/${id}/`, {
      headers
    });
  }
}
