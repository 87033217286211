export function getHttpErrorHelper(error: object): string {
  const keys = Object.keys(error);
  let errorText: string = '';

  if (keys.length) {
    errorText = error[keys[0]].join(' ');
  }

  return errorText;
}
