// ANGULAR
import { Component } from '@angular/core';

// CORE
import {ISelectedFolderInTiff} from '@core/interfaces';

// CURRENT
import { BatcheService } from './../../../../services/batche.service';

@Component({
  selector: 'app-doc-preview',
  templateUrl: './doc-preview.component.html',
  styleUrls: ['./doc-preview.component.scss']
})
export class DocPreviewComponent  {

  public isOpenMarkSepMenu = false;
  public selectedDoc = null;

  constructor(
    private batcheService: BatcheService,
  ) { }

  public get selectedFolderInTiff(): ISelectedFolderInTiff {
    return this.batcheService.selectedFolderInTiff;
  }

  public get previewIndex(): number {
    return this.batcheService.previewIndex;
  }

  public get menuIndex(): number {
    return this.batcheService.menuIndex;
  }

  public get docMarkedSeparator(): number[] {
    return this.batcheService.docMarkedSeparator;
  }

  public confirmMarkSeparator(): void {
    this.batcheService.confirmMarkSeparator();
  }

  public clickDoc(event: Event, index: number): void {
    event.preventDefault();
    this.batcheService.menuIndex = index;
    event.stopPropagation();
  }

  public openPreview(event: Event, index: number): void {
    const doc = (event.currentTarget as HTMLElement).parentElement.parentElement;
    const isSelected = doc.classList.contains('preview');

    this.batcheService.previewIsOpen = !isSelected;
    this.batcheService.previewIndex = isSelected ? null : index;

    event.stopPropagation();
  }

  public selectDoc(event: Event): void {
    if (this.selectedDoc !== this.batcheService.menuIndex) {
      this.selectedDoc = this.batcheService.menuIndex;
    } else {
      this.selectedDoc = null;
    }
    event.stopPropagation();
  }

  public openMenuMarkSeparator(event: Event): void {
    this.isOpenMarkSepMenu = true;
    event.stopPropagation();
  }

  public closeMenuMarkSeparator(): void {
    this.isOpenMarkSepMenu = false;
  }

  public getDocName(name: string): string {
    return `${name.slice(name.lastIndexOf('/') + 1, name.search(/.jpeg/))}`;
  }

  public closeMenu(): void {
    this.batcheService.menuIndex = null;
  }

}
