// ANGULAR
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';

// RXJS
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// CURRENT
import { NotificationService } from 'src/app/core/services/notification.service';
import {AuthUserService} from '../../auth/services';

@Injectable()
export class ParamInterceptor implements HttpInterceptor {

  private apiList = ['auth/signup', 'auth/password-change'];

  constructor(
    private userService: AuthUserService,
    private router: Router,
    private http: HttpClient,
    private noticeService: NotificationService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });

    return next.handle(req).pipe(
      catchError( (error: HttpErrorResponse) => {
        if (error.status === 401 && !this.requestIsSent) {
          this.userService.refreshToken();
        }

        if (error.status === 400 && !this.apiList.includes(this.getApiType(error.url))) {
          this.catchServerError(error.status);
        }

        return throwError(error);
      })
    );
  }

  private get requestIsSent(): boolean {
    return this.userService.requestIsSent;
  }

  private catchServerError(status: number): void {
    const TYPE = 'error';

    switch (status) {
      case 400:
        this.noticeService.setNotice('400: Bad Request', TYPE);
        break;

      case 401:
        this.noticeService.setNotice('401: Unauthorized', TYPE);
        break;

      case 402:
        this.noticeService.setNotice('402: Payment Required', TYPE);
        break;

      case 403:
        this.noticeService.setNotice('403: You might not have the necessary permissions for a resource', TYPE);
        break;

      case 404:
        this.noticeService.setNotice('404: Not Found', TYPE);
        break;

      case 500:
        this.noticeService.setNotice('500: Internal Server Error', TYPE);
        break;

      case 501:
        this.noticeService.setNotice('501: Not Implemented', TYPE);
        break;

      case 502:
        this.noticeService.setNotice('502: Bad Gateway', TYPE);
        break;

      case 503:
        this.noticeService.setNotice('503: Service Unavailable', TYPE);
        break;

      default: {
        this.noticeService.setNotice('Error', TYPE);
      }
    }
  }

  private getApiType(url: string): string {
    return url.slice(url.indexOf('api/') + 4, url.lastIndexOf('/'));
  }

}
