// ANGULAR
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  public isPrinting = false;

  constructor(private router: Router) {
  }

  public printDocument(documentName: string, documentData: string[], params?: {}): void {
    this.isPrinting = true;

    const navigationExtras =  { queryParams: params };
    const commands = [
      {
        outlets: {
          print: ['print', documentName, documentData.join()]
        },
      },
    ];

    this.router.navigate(commands, navigationExtras);
  }

  public onDataReady(): void {
    this.isPrinting = true;
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{outlets: {print: null}}]);
    }, 1000);
  }
}
