// ANGULAR
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';

// RXJS
import {
  Observable,
  ReplaySubject,
  Subject
} from 'rxjs';

import { repeatWhen, takeUntil, tap } from 'rxjs/operators';

// CORE
import { ShareApiService } from '@core/services';
import { ILibrary, IShare } from '@core/interfaces';
import { PERMISSIONS } from '../../../../../core/lib';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})

export class ShareComponent implements OnInit, OnDestroy {

  @Input() public library: ILibrary;

  public emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  public permissionMap: Set<string> = new Set<string>();
  public permissions = PERMISSIONS;

  public shareList$ = new Observable<IShare[]>();
  public shareCount = 0;

  private updateList$ = new ReplaySubject<void>();
  private destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private shareApiService: ShareApiService) {
  }

  public ngOnInit(): void {
    this.getShareList(this.library.id);

    this.permissionMap.add(this.permissions.read);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onSubmit(): void {

    if (this.emailCtrl.invalid && !this.permissionMap.size) {

      this.emailCtrl.markAsTouched();
    } else {

      const queryParams: IShare = {
        library: this.library.id,
        email: this.emailCtrl.value,
        permission: this.permissionMap.values().next().value
      };

      this.shareApiService.createSharing(queryParams)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe({
          next: (info: any) => {
            this.updateList$.next();
            this.permissionMap.clear();
            this.emailCtrl.reset();
          },
          error: () => {
          },
        }
      );
    }
  }

  public addPermission(permission: string): void {
    this.permissionMap.clear();
    this.permissionMap.add(permission);
  }

  public trackByFn(index: number): number {
    return index;
  }

  private getShareList(libraryId: string): void {
    this.shareList$ = this.shareApiService.shareList(libraryId)
    .pipe(
      tap((shareList: IShare[]) => this.shareCount = shareList.length),
      repeatWhen(() => this.updateList$),
    );
  }
}
