// ANGULAR
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ENVIRONMENT
import { environment } from '@environments/environment';


@Injectable()
export class BaseApiService {

  protected apiServerUrl = environment.API_SERVER_URL;

  constructor(protected httpClient: HttpClient) {
  }

  public getToken(): string {
    return `Bearer ${localStorage.getItem('access_token')}`;
  }
}
