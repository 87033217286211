// ANGULAR
import { Injectable } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

// CORE
import { BaseApiService } from './base-api.service';
import { IFolder, ILibraryParams } from '@core/interfaces';


@Injectable()
export class FolderApiService extends BaseApiService {

  public createFolder(bodyParams: IFolder): Observable<IFolder> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.post<IFolder>(`${this.apiServerUrl}/api/folders/`, bodyParams, {headers});
  }

  public getFolders(queryParams: ILibraryParams): Observable<IFolder[]> {
    const headers = {
      Authorization: this.getToken()
    };

    return this.httpClient.get<IFolder[]>(`${this.apiServerUrl}/api/folders/`, {headers, params: queryParams as any})
    .pipe(
      pluck('results')
    );
  }
}
