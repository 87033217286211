// ANGULAR
import { Injectable, OnDestroy } from '@angular/core';

// RXJS
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// CORE
import { IParsedTiffFile, ISelectedFolderInTiff, ILibrary } from '@core/interfaces';
import { BatchesApiService } from '@core/services';

@Injectable()
export class BatcheService implements OnDestroy {
  // batche
  public selectedLibrary: ILibrary;
  public updateTiffData = new Subject<boolean>();
  public previewIsOpen = false;
  public docListIsOpen = false;
  public parsedTiff: IParsedTiffFile;

  // doc list
  public selectedFolderInTiff: ISelectedFolderInTiff = {files: [], id: ''};

  // doc preview
  public showImgList = false;
  public menuIndex = null;

  // image preview
  public previewIndex: number = null;

  // mark separator
  public docMarkedSeparator: number[] = [];
  public docMarkedSeparatorData = { separators: []};

  private destroyed$ = new Subject<void>();

  constructor(
    private batchesApi: BatchesApiService,
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public confirmMarkSeparator(): void {
    this.batchesApi.editTiff( this.docMarkedSeparatorData, this.selectedFolderInTiff.id)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(res => {
      this.docMarkedSeparator.splice(0, this.docMarkedSeparator.length);
      this.updateTiffData.next(true);
      this.selectedFolderInTiff = {files: [], id: ''};
      this.showImgList = false;
      this.previewIsOpen = false;
    });
  }

  public unselectedPreview(): void {
    this.previewIndex = null;
  }

  public closePreview(): void {
    this.previewIsOpen = false;
    this.unselectedPreview();
  }

  public closeManageFile(): void {
    this.docListIsOpen = false;
    this.selectedLibrary = null;
    this.previewIsOpen = false;
    this.showImgList = false;
    this.selectedFolderInTiff = {files: [], id: ''};
    this.previewIndex = null;
    this.menuIndex = null;
  }
}
