// ANGULAR
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';

// RXJS
import {Observable} from 'rxjs';

// CORE
import {IListResponse} from '@core/interfaces';

// CURRENT
import {BaseApiService} from './base-api.service';

@Injectable()
export class FileApiService extends BaseApiService {
  public getFilesList(bodyParams: any, id: string): Observable<IListResponse> {
    const headers = {
      Authorization: this.getToken(),
    };

    const params = new HttpParams()
    .set('offset', bodyParams.offset)
    .set('limit', bodyParams.limit);

    return this.httpClient.get<IListResponse>(`${this.apiServerUrl}/api/documents/${id}/files/`, {headers, params});
  }
}
