// ANGULAR
import {
  Component,
  OnChanges,
  ComponentFactory,
  ComponentRef,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';

// RXJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

// CURRENT
import { LoadingFileComponent } from '../loading/loading-file.component';

@Component({
  selector: 'app-add-files',
  templateUrl: './add-files.component.html',
  styleUrls: ['./add-files.component.scss']
})
export class AddFilesComponent implements OnDestroy, OnChanges {

  @Input() public folderData = {id: '', library: ''};
  @Output() public updateList = new EventEmitter();

  @ViewChild('loadingFilesList', { static: false, read: ViewContainerRef}) container;

  public isLoaderListOpen = false;
  private containerIndex = 0;

  private destroyed$ = new Subject<void>();

  constructor(
    private resolver: ComponentFactoryResolver,
    ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.folderData.firstChange && changes.folderData.previousValue.id !== changes.folderData.currentValue.id) {
      this.isLoaderListOpen = false;
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  public toggleList(event: any): void {
    const loadingList = event.currentTarget.parentNode.querySelector('#loading-files-list');
    const loadBtn = event.currentTarget.parentNode.querySelector('#load-file');

    if (!loadingList.childElementCount) {
      loadBtn.click();
    } else {
      this.isLoaderListOpen = !this.isLoaderListOpen;
    }
  }

  public createComponent(event: Event): void {
    if ((event.target as HTMLInputElement).files.length) {
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(LoadingFileComponent);
      const component: ComponentRef<LoadingFileComponent> = this.container.createComponent(factory);
      component.instance.fileData = (event.target as HTMLInputElement).files[0];
      component.instance.folderData = this.folderData;
      component.instance.containerIndex = this.containerIndex;
      component.instance.updateFilesList.pipe(takeUntil(this.destroyed$)).subscribe(() => this.updateFilesList());
      component.instance.openList.pipe(takeUntil(this.destroyed$)).subscribe(() => this.openList());
      component.instance.closeLoader.pipe(takeUntil(this.destroyed$)).subscribe(() => this.closeLoader());
      component.instance.deleteSelf.pipe(tap(() => component.destroy(), takeUntil(this.destroyed$))).subscribe();

      (event.target as HTMLInputElement).value = '';
      this.containerIndex = this.containerIndex + 1;
    }
  }

  public closeLoader(): void {
    this.isLoaderListOpen = false;
  }

  public updateFilesList(): void {
    this.updateList.emit();
  }

  public openList(): void {
    if (!this.isLoaderListOpen) {
      this.isLoaderListOpen = true;
    }
  }
}
