// ANGULAR
import { Component, HostListener } from '@angular/core';

// CORE
import { NotificationService } from '@core/services';
import { PrintService } from './main/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'reliaDocs';

  public get isOpened(): boolean {
    return this.noticeService.isOpened;
  }

  constructor(
    private printService: PrintService,
    private noticeService: NotificationService) {
  }
}
