// ANGULAR
import { HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';

// RXJS
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// CORE
import {  IFile, ILoadingFile, IFolder } from '@core/interfaces';
import { getFileSizeHelper } from '@core/helpers';

// CURRENT
import { SendFile } from './../../../services/send-file.service';


@Component({
  selector: 'app-loading-file',
  templateUrl: './loading-file.component.html',
  styleUrls: ['./loading-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingFileComponent implements OnInit, OnDestroy {

  @Input() public containerIndex: number;
  @Input() public deleteSelf = new EventEmitter<boolean>();
  @Input() public fileData: IFile;
  @Input() public folderData: IFolder;
  @Output() public updateFilesList = new EventEmitter<boolean>();
  @Output() public openList = new EventEmitter<boolean>();
  @Output() public closeLoader = new EventEmitter<boolean>();

  public percentDone = 0;
  public fileSize: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    public sendFileService: SendFile
  ) { }

  public ngOnInit(): void {
    this.loadFile();
    this.fileSize = getFileSizeHelper(this.fileData.size);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getName(name: string): string {
    return name.slice(0 , name.lastIndexOf('.'));
  }

  private loadFile(): void {
    this.openList.emit(true);
    this.sendFileService.loadFile(this.folderData, this.fileData, this.containerIndex);
    this.subscribeLoadFile();
  }

  private subscribeLoadFile(): void {
    this.sendFileService.sendFile$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (event: Map<number, ILoadingFile>) => {
        const data = event.get(this.containerIndex) as ILoadingFile;

        if (data.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * data.loaded / data.total);
          this.cdRef.markForCheck();
        }

        if (!!data.body) {
          this.deleteLoading();

          setTimeout(() => {
            this.updateFilesList.emit(true);
          }, 3000);
        }
      }
    );
  }

  private deleteLoading(): void {
    const parent = document.getElementById('loading-files-list');

    this.deleteSelf.emit(true);

    if (parent.childElementCount <= 1) {
      this.closeLoader.emit(true);
    }
  }
}
