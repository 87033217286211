// ANGULAR
import { FormControl } from '@angular/forms';

// CORE
import { changeFormatDate } from '../helpers';

export class CustomValidators {

  static dateFormat(control: FormControl): { [key: string]: boolean } {

    if (control.value) {

      const date = changeFormatDate(control.value);
      const isValidDate = Date.parse(date);

      if (isNaN(isValidDate)) {
        return {invalidDate: true};
      }
    }

    return null;
  }
}
