// ANGULAR
import { Injectable } from '@angular/core';

// RXJS
import { Subject } from 'rxjs';

// CORE
import { IFolder, ILibrary, IDocument} from '@core/interfaces';

@Injectable()
export class LibrariesService {

  public selectedLibrary = new Subject<ILibrary>();
  public selectedFolder = new Subject<IFolder>();
  public selectedDocument = new Subject<IDocument>();
}
