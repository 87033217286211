// ANGULAR
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

// RXJS
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { repeatWhen, takeUntil, tap } from 'rxjs/operators';

// CORE
import {
  DocumentTypesApiService,
  FolderApiService,
  SeparatorsApiService
} from '@core/services';

import { IDocType, IFolder, ILibrary, ILibraryParams, ISeparator } from '@core/interfaces';
import { PrintService } from '../../../../services';

@Component({
  selector: 'app-separators',
  templateUrl: './separators.component.html',
  styleUrls: ['./separators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatorsComponent implements OnInit, OnDestroy {

  @Input() public library: ILibrary;

  public separators$ = new Observable<ISeparator[]>();
  public separatorsCount = 0;
  public updateList$ = new ReplaySubject();

  public selectedSeparator: ISeparator;
  public isEditingSeparator = false;
  public isCreatingSeparator = false;

  public printSeparatorMap: Set<string> = new Set<string>();

  public docTypes$ = new Observable<IDocType[]>();
  public isOpenSelectDoctype = false;

  public folders$ = new Observable<IFolder[]>();
  public isOpenSelectFolder = false;
  public folderCreated$ = new ReplaySubject();

  public form = this.fb.group({
    document_name: ['', Validators.required],

    folder: this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
    })
  });

  private libraryParams: ILibraryParams;
  private destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private separatorsApiService: SeparatorsApiService,
              private documentTypesApiService: DocumentTypesApiService,
              private folderApiService: FolderApiService,
              private printService: PrintService) {
  }

  public ngOnInit(): void {

    this.libraryParams = {
      library: this.library.id,
    };

    this.getSeparators();

    this.getFolders();

    this.getDocTypes();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  public toggleFormSeparator(): void {
    this.isCreatingSeparator = !this.isCreatingSeparator;
    this.isEditingSeparator = false;
    this.form.reset();

    if (!this.isCreatingSeparator) {
      this.cleanForm();
    }
  }

  public selectSeparator(separator: ISeparator): void {
    this.selectedSeparator = separator;
  }

  public addToPrint(id: string): void {
    if (this.printSeparatorMap.has(id)) {
      this.printSeparatorMap.delete(id);
    } else {
      this.printSeparatorMap.add(id);
    }
  }

  public editSeparator(separator: ISeparator): void {

    const separatorData = {
      document_name: separator.document_name,
      folder: {
        id: separator.folder,
        name: separator.folder_name,
      }
    };

    this.form.reset();
    this.form.patchValue(separatorData);

    this.selectedSeparator = separator;
    this.isOpenSelectFolder = false;
    this.isEditingSeparator = true;
  }

  public deleteSeparator(id: string): void {
    this.separatorsApiService.deleteSeparator(id)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {
      this.updateList$.next();
    });
  }

  public selectFolder(folder: IFolder): void {

    this.form.get('folder').setValue({
      id: folder.id,
      name: folder.name,
    });

    this.isOpenSelectFolder = false;
  }

  public selectDocType(docType: IDocType): void {
    this.form.patchValue({
      document_name: docType.document_types_name
    });

    this.isOpenSelectDoctype = false;
  }

  public onSubmit(): void {
    if (this.form.invalid) {

      this.form.markAllAsTouched();
    } else {

      const formValuesClone = {
        ...this.form.value
      };

      formValuesClone.folder = formValuesClone.folder.id;

      if (this.isCreatingSeparator) {

        this.createNewSeparator(formValuesClone);
      } else {

        this.editCurrentSeparator(formValuesClone);
      }
    }
  }

  public trackByFn(index: number): number {
    return index;
  }

  private cleanForm(): void {
    this.isCreatingSeparator = false;
    this.isEditingSeparator = false;
    this.isOpenSelectFolder = false;

    this.selectedSeparator = null;

    this.form.reset();
  }


  private createNewSeparator(dataForm: ISeparator): void {
    this.separatorsApiService.createSeparators(dataForm)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
        next: () => {
          this.updateList$.next();
          this.cleanForm();
        },
        error: () => {
        },
      }
    );
  }


  public printSeparators(separatorsMap: Set<string> = new Set()): void {

    const separatorsIds = [...separatorsMap.values()];
    this.print(separatorsIds);
  }

  public printSelectedSeparator(event: Event, separatorId: string): void {
    this.print([separatorId]);
    event.stopPropagation();
  }


  public print(separatorsIds: string[] = []): void {

    const libraryParams = {
      library: this.library.id,
    };

    this.printService.printDocument('separators', separatorsIds, libraryParams);
  }

  public updateFolderList(folder: IFolder): void {
    this.folderCreated$.next();

    this.selectFolder(folder);
  }

  private getDocTypes(): void {
    this.docTypes$ = this.documentTypesApiService.getDocTypes(this.libraryParams);
  }

  private editCurrentSeparator(dataForm: ISeparator): void {
    this.separatorsApiService.editSeparator(this.selectedSeparator.id, dataForm)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
        next: () => {
          this.updateList$.next();
          this.cleanForm();
        },
        error: () => {
        },
      }
    );
  }

  private getSeparators(): void {
    this.separators$ = this.separatorsApiService.getSeparators(this.libraryParams)
    .pipe(
      tap((separatorList: ISeparator[]) => this.separatorsCount = separatorList.length),
      repeatWhen(() => this.updateList$),
    );
  }

  private getFolders(): void {
    this.folders$ = this.folderApiService.getFolders(this.libraryParams)
    .pipe(
      repeatWhen(() => this.folderCreated$),
    );
  }
}
