// ANGULAR
import {Injectable, OnDestroy} from '@angular/core';

// CORE
import {IGetLoadedTiff, ILoadedTiff, ILoadingFile, ISendingTiffsData} from '@core/interfaces';
import { BatchesApiService, NotificationService } from '@core/services';

// RXJS
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SendTiff implements OnDestroy {

  public sendingTiffs = new BehaviorSubject<Map<number, ILoadingFile>>(new Map<0, ILoadingFile>());
  public sendingTiffsData = new Map<number, ISendingTiffsData>();
  public tiffs: ILoadedTiff[] = [];
  public getTiffsNext = true;
  public tiffsOffset = 0;
  public tiffsIsLoading = false;
  public loadingProgressInHeader = 0;
  private loadingIndexInHeader = 0;
  public loadingIsDoneInHeader = false;

  private destroyed$ = new Subject<void>();

  private _destroyers$ = [];

  constructor(
    private bathcesApiService: BatchesApiService,
    private noticeService: NotificationService,
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getSendingTiff(): ILoadingFile[] {
    const newArray = [] as ILoadingFile[];

    this.sendingTiffs.getValue().forEach((val: ILoadingFile) => {
      newArray.push(val);
    });

    return newArray;
  }

  public getSendingTiffsData(): ISendingTiffsData[] {
    const newArray = [] as ISendingTiffsData[];

    this.sendingTiffsData.forEach( (val: ISendingTiffsData) => {
      newArray.push(val);
    });
    return newArray;
  }

  public sendTiff(file: object, libraryId: string, name: string, componentIdx: number): void {
    const subject = {};
    subject[componentIdx] = new Subject<void>();

    this._destroyers$.push(subject);

    if (this.loadingIsDoneInHeader) {
      this.loadingIsDoneInHeader = false;
    }

    this.bathcesApiService.sendTiffFile(file, libraryId, name)
    .pipe(takeUntil(subject[componentIdx]))
    .subscribe(
      (res: ILoadingFile) => {
        const newData = this.sendingTiffs.value;

        newData.set( componentIdx, res);
        this.sendingTiffs.next(newData);

        this.getLoadingProgress();

        if (res.type === 3) {
          this.noticeService.setNotice('Batch has been successfully uploaded.', 'success');
        }
      }
    );
  }

  public getTiffs(): void {
    this.tiffsIsLoading = true;
    this.bathcesApiService.getTiffs({limit: 10, offset: this.tiffsOffset})
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (res: IGetLoadedTiff) => {
        this.tiffs = this.tiffs.concat(res.results);
        this.tiffsOffset = this.tiffsOffset + 10;
        this.tiffsIsLoading = false;

        if (res.next === null) {
          this.getTiffsNext = false;
        }
      },
    );
  }

  public closeStream(idx: number): void {
    const subjectToStop = this._destroyers$.find( subject => {
      const key = Object.keys(subject).toString();

      return +key === idx;
    });

    subjectToStop[idx].next();
    subjectToStop[idx].complete();
    this._destroyers$.splice( idx, 1);
  }

  public sortData(): void {
    this.sortSendingTiffs();
    this.sortSendingTiffsData();
  }

  private sortSendingTiffs(): void {
    const apiData = this.sendingTiffs.value;
    const newApiData = new Map<number, ILoadingFile>();
    let lastIndex = 0;

    apiData.forEach((val: ILoadingFile) => {
      newApiData.set(lastIndex, val);
      lastIndex++;
    });

    this.sendingTiffs.next(newApiData);
  }

  private sortSendingTiffsData(): void {
    const sendingTiffData = this.sendingTiffsData;
    const newSendingApiData = new Map<number, ISendingTiffsData>();
    let lastIndex = 0;

    sendingTiffData.forEach((val: ISendingTiffsData) => {
      newSendingApiData.set(lastIndex, val);
      lastIndex++;
    });

    this.sendingTiffsData.clear();
    this.sendingTiffsData = newSendingApiData;
  }

  private getLoadingProgress(): void {
    const data = this.sendingTiffs.value.get(this.loadingIndexInHeader);

    if (data === undefined) {
      return this.closeLoaderInHeader();
    }

    if (data.type === 1) {
      this.loadingProgressInHeader = Math.round(100 * data.loaded / data.total);
    } else if (data.type >= 2) {
      this.controlLoadingQueue();
    }
  }

  private controlLoadingQueue(): void {
    const data = this.sendingTiffs.value;

    if (!data.has(this.loadingIndexInHeader + 1)) {
      this.loadingIsDoneInHeader = true;
    }

    if (data.has(this.loadingIndexInHeader + 1)) {
      this.loadingIndexInHeader = this.loadingIndexInHeader + 1;
    }
  }

  public closeLoaderInHeader(): void {
    const data = this.sendingTiffs.value;

    data.forEach( (item: ILoadingFile, index) => {
      if (item.type === 1) {
        return;
      }
    });

    this.loadingIndexInHeader = this.loadingIndexInHeader + 1;
    this.loadingProgressInHeader = 0;
    this.loadingIsDoneInHeader = false;
  }
}
