export function getFileSizeHelper(size: number ): string {
  if (!size || size === 0) {
    return '0 b';
  }

  const i = Math.floor(Math.log(size) / Math.log(1024));
  const convertedSize = (size / Math.pow(1024, i)).toFixed(0);

  switch (i) {
    case 0:
      return `${convertedSize} B`;
    case 1:
      return `${convertedSize} KB`;
    case 2:
      return `${convertedSize} MB`;
    case 3:
      return `${convertedSize} GB`;
    case 4:
      return `${convertedSize} TB`;
  }
}
