// ANGULAR
import { Injectable } from '@angular/core';

// RXJS
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

// CORE
import { BaseApiService } from './base-api.service';
import { IDocType, ILibraryParams } from '@core/interfaces';


@Injectable()
export class DocumentTypesApiService extends BaseApiService {

  public getDocTypes(queryParams: ILibraryParams): Observable<IDocType[]> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    return this.httpClient.get<IDocType[]>(`${this.apiServerUrl}/api/document_types/`, {
      headers,
      params: queryParams as any
    })
    .pipe(
      pluck('results')
    );
  }

  public getDocTypesByIds(ids: string[]): Observable<IDocType[]> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    const bodyParams = {document_type_list: ids};
    return this.httpClient.post<IDocType[]>(`${this.apiServerUrl}/api/document_types/list/`, bodyParams, {headers});
  }

  public createDocType(bodyParams: IDocType): Observable<IDocType> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    return this.httpClient.post<IDocType>(`${this.apiServerUrl}/api/document_types/`, bodyParams, {headers});
  }

  public deleteDocType(id: string): Observable<any> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    return this.httpClient.delete(`${this.apiServerUrl}/api/document_types/destroy/${id}/`, {
      headers
    });
  }

  public editDocType(id: string, bodyParams: IDocType): Observable<any> {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    return this.httpClient.put(`${this.apiServerUrl}/api/document_types/${id}/`, bodyParams, {
      headers
    });
  }
}
