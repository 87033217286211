// ANGULAR
import { HttpEventType } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef
} from '@angular/core';

// RXJS
import {Observable, Subject, interval, timer} from 'rxjs';
import { takeUntil, tap, audit } from 'rxjs/operators';

// CORE
import {
  ILibrary,
  IParsedTiffFile,
  ITiffData,
  ILoadingFile,
  IFile,
  ILoadedTiff, ISendingTiffsData
} from '@core/interfaces';
import { LibraryApiService, BatchesApiService } from '@core/services';
import { getFileSizeHelper } from '@core/helpers';
import { TiffStatusEnum } from '@core/enums';

// CURRENT
import { SendTiff } from './../../../../services/send-tiff.service';
import { BatcheService } from './../../../../services/batche.service';

@Component({
  selector: 'app-download-batches',
  templateUrl: './download-batches.component.html',
  styleUrls: ['./download-batches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadBatchesComponent implements OnInit, OnDestroy {

  @Input() componentIndex: number;
  @Input() loadedTiff: ILoadedTiff;
  @Output() createComp = new EventEmitter<boolean>();
  @Output() destroyComp = new EventEmitter<boolean>();

  public choiceLibClass = 'file-section__choice-lib';
  public fileData: IFile;
  public tiffData: ITiffData;
  public isLoadFile = false;
  public clickManageFile = false;
  public libraries$ = new Observable<ILibrary[]>();
  public currentLibrary: ILibrary = {name: '', id: ''};
  public percentDone = 0;
  public countDocs = 0;
  public countPages = 0;
  public isConfirmed = false;
  public parsedTiff: IParsedTiffFile;
  public loadingTiffStatus = 'uploading';
  public readonly tiffStatusEnum = TiffStatusEnum;
  public fileSize: string;

  public isLoadingBatch = false;
  public isLoadedBatch = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private libraryApiService: LibraryApiService,
    private bathcesApiService: BatchesApiService,
    private sendTiffService: SendTiff,
    private batcheService: BatcheService,
    private cdRef: ChangeDetectorRef,
    private elRef: ElementRef
  ) {
    this.updateTiffData();
  }

  public ngOnInit(): void {
    if (this.loadedTiff) {
      this.initForLoaded();
    } else {
      this.initForLoading();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private get currentSendingTiffData(): ISendingTiffsData {
    return this.sendTiffService.getSendingTiffsData()[this.componentIndex];
  }

  public initForLoading() {
    this.isLoadingBatch = true;
    this.libraries$ = this.libraryApiService.getLibraries();

    if (!!this.currentSendingTiffData) {
      this.currentLibrary = {
        name: this.currentSendingTiffData.library.name,
        id: this.currentSendingTiffData.library.id
      };

      this.fileData = {
        name: this.currentSendingTiffData.name,
        size: this.currentSendingTiffData.size
      };

      this.subscribeBatch(this.componentIndex);
      this.isLoadFile = true;
    }
  }

  public initForLoaded() {
    this.isLoadedBatch = true;
    this.isLoadFile = true;
    this.loadingTiffStatus = this.loadedTiff.status;
    this.percentDone = 100;
    this.countDocs = this.loadedTiff.count_parsed_documents;
    this.countPages = this.loadedTiff.count_files_pages;

    this.currentLibrary = {
        name: this.loadedTiff.library_name,
        id: this.loadedTiff.library
      };

    this.fileData = {
      name: this.loadedTiff.name,
      size: this.loadedTiff.file_size
    };

    this.tiffData = {
      file: this.loadedTiff.file,
      folder_url: this.loadedTiff.folder_url,
      id: this.loadedTiff.id,
      library: this.loadedTiff.library,
    };
    this.fileSize = getFileSizeHelper(this.fileData.size);
  }

  public openLibList(): void {
    this.choiceLibClass = (this.choiceLibClass.indexOf('active') !== -1) ? 'file-section__choice-lib' : 'file-section__choice-lib active';
  }

  public loadFile(event: any): void {
    const index = this.getIndex(event);

    this.loadingTiffStatus = TiffStatusEnum.uploading;

    this.fileData = (event.target as HTMLInputElement).files[0];

    const file = {
      library: this.currentLibrary,
      name: this.fileData.name,
      size: this.fileData.size
    };

    this.isLoadFile = true;

    this.sendTiffService.sendTiff(this.fileData, this.currentLibrary.id, this.fileData.name, index);

    this.sendTiffService.sendingTiffsData.set(index, file);
    this.subscribeBatch(index);
  }

  public getIndex(event): number {
    const elemList = this.elRef.nativeElement.parentNode.querySelectorAll('.loading-batch');
    let index;

    elemList.forEach( (item, idx) => {
      if (event.path.includes(item)) {
        index = idx;
      }
    });

    return index;
  }

  public subscribeBatch(index: number): void {
    let create = false;
    let isSub = false;
    this.sendTiffService.sendingTiffs
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      event => {
        const data = event.get(index) as ILoadingFile;

        if (!!data && data.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * data.loaded / data.total);
          if (this.percentDone === 100 && create === false) {
            this.loadingTiffStatus = TiffStatusEnum.uploaded;
            create = true;
            this.createComp.emit(true);
          }

          if (!this.fileSize) {
            this.fileSize = getFileSizeHelper(data.total);
          }
        }

        if (!!data && data.type > 2 && !create) {
          this.fileSize = getFileSizeHelper(data.body.file_size);
          this.percentDone = 100;
          this.loadingTiffStatus = TiffStatusEnum[data.body.status];
          create = true;
        }

        if (!!data &&
          this.loadingTiffStatus !== TiffStatusEnum.processed &&
          this.loadingTiffStatus !== TiffStatusEnum.publishing &&
          !!data.body && !isSub) {

          this.tiffData = data.body;
          this.getTiffData(data.body);

          isSub = true;
        }

        this.cdRef.markForCheck();
      }
    );
  }

  public updateTiffData(): void {
    this.batcheService.updateTiffData
    .pipe(
      tap(value => {
        if (value && this.clickManageFile) {
          this.getTiffData(this.tiffData);
        }
      }),
      audit(() => interval(1000)),
      tap(value => {
        if (value) {
          this.batcheService.updateTiffData.next(false);
        }
      }),
      takeUntil(this.destroyed$)
    )
    .subscribe();
  }

  public confirmTiffData(event: MouseEvent): void {
    const wrapEl = this.elRef.nativeElement.querySelectorAll('.top-section');

    this.loadingTiffStatus = TiffStatusEnum.publishing;
    this.batcheService.closeManageFile();

    if (this.isLoadingBatch) {
      this.sendTiffService.closeLoaderInHeader();
    }

    wrapEl.forEach((elem, idx) => {

      if (wrapEl[idx].classList.contains('active')) {
        wrapEl[idx].classList.remove('active');
      }

    });

    this.bathcesApiService.confirmTiffData(this.tiffData, this.fileData)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        this.isConfirmed = true;
        this.cdRef.markForCheck();
        this.deleteLoadBatch(event);
      }
    );
  }

  public deleteLoadBatch(event: any): void {
    const apiData = this.sendTiffService.sendingTiffs.value;
    const index = this.getIndex(event);

    if (this.loadedTiff) {
      this.sendTiffService.getTiffs();
    } else {
      const newData = new Map<number, ILoadingFile>();

      apiData.forEach((val: ILoadingFile, idx: number) => {
        if (index === idx) {
          const newVal = val;
          newVal.body.status = TiffStatusEnum.publishing;

          newData.set(idx, newVal);
        } else {
          newData.set(idx, val);
        }
      });

      this.sendTiffService.sendingTiffs.next(newData);

      this.manageFile(event);
    }
    this.cdRef.markForCheck();
  }

  public getName(name: string): string {
    return name.slice(0, name.lastIndexOf('.'));
  }

  public manageFile(event: MouseEvent): void {
    const wrapEl = this.elRef.nativeElement.querySelectorAll('.top-section');
    let docListIsOpen = false;

    if (!!event) {
      wrapEl.forEach((elem, idx) => {
        if (event.composedPath().includes(elem)) {
          if (!wrapEl[idx].classList.contains('active')) {
              wrapEl[idx].classList.add('active');
            } else {
              wrapEl[idx].classList.remove('active');
            }
        } else {
          wrapEl[idx].classList.remove('active');
          this.batcheService.parsedTiff = null;
          this.batcheService.selectedFolderInTiff = {files: [], id: ''};
        }

        if (wrapEl[idx].classList.contains('active')) {
          docListIsOpen = true;
        }
      });
    }

    if (docListIsOpen) {
      if (this.loadedTiff && !this.parsedTiff) {
        this.getTiffData(this.tiffData);
      } else {
        this.batcheService.parsedTiff = this.parsedTiff;
      }

      this.batcheService.selectedLibrary = this.currentLibrary;
      this.batcheService.docListIsOpen = true;
    } else {
      this.batcheService.closeManageFile();
      this.batcheService.parsedTiff = null;
      this.batcheService.selectedFolderInTiff = {files: [], id: ''};
    }
    this.clickManageFile = true;
  }

  public getTiffData(data: ITiffData): void {
    this.bathcesApiService.getTiffData(data.id)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        if (res.body.status === 'processing' || res.body.status === 'uploaded') {
          timer(5000)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
              this.getTiffData(data);
            }
          );

          if (this.loadingTiffStatus !== TiffStatusEnum.processing) {
            this.loadingTiffStatus = TiffStatusEnum.processing;
          }

        } else if (res.body.status === 'error') {
          this.loadingTiffStatus = TiffStatusEnum.error;
        } else {
          this.loadingTiffStatus = TiffStatusEnum.processed;
        }

        this.parsedTiff = res.body;
        this.countDocs = res.body.parsed_documents.length;

        this.countPages = 0;

        res.body.parsed_documents.forEach(doc => {
          this.countPages = this.countPages + doc.files.length;
        });

        if (this.clickManageFile) {
          this.batcheService.parsedTiff = res.body;
        }
        this.cdRef.markForCheck();
      }
    );
  }

  public selectLibrary(data): void {
    if (this.currentLibrary.id !== data.id) {
      this.isLoadFile = false;
    }
    this.currentLibrary = data;
  }

  public deleteLoadFile(event): void {
    const index = this.getIndex(event);

    this.isLoadFile = false;
    this.fileSize = null;
    this.loadingTiffStatus = TiffStatusEnum.uploading;
    this.sendTiffService.closeStream(index);
  }
}
