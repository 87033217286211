// ANGULAR
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// CORE
import { SeparatorsApiService } from '@core/services';
import { ILibraryParams, ISeparator } from '@core/interfaces';

// CURRENT
import { PrintService } from '../../../../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-print-separators',
  templateUrl: './print-separators.component.html',
  styleUrls: ['./print-separators.component.scss'],
  providers: [SeparatorsApiService],
})

export class PrintSeparatorsComponent implements OnInit, OnDestroy {

  public separatorIds = [];
  public separatorList: ISeparator[];

  public library: ILibraryParams;

  private destroyed$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private printService: PrintService,
              private separatorsApiService: SeparatorsApiService) {
  }

  public ngOnInit(): void {

    if (this.route.snapshot.params.separatorIds) {
      this.separatorIds = this.route.snapshot.params.separatorIds
      .split(',');
    }

    this.library = {
      library: this.route.snapshot.queryParamMap.get('library')
    };

    this.print();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private print(): void {

    if (this.separatorIds.length) {
      this.printSeparatorsByIds(this.separatorIds);
    } else {
      this.printAllSeparators();
    }
  }

  private printSeparatorsByIds(separatorIds: string[]): void {
    this.separatorsApiService.getSeparatorsByIds(this.separatorIds)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe((separators: ISeparator[]) => {

      this.separatorList = separators;
      this.printService.onDataReady();
    });
  }

  private printAllSeparators(): void {

    this.separatorsApiService.getSeparators(this.library)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe((separators: ISeparator[]) => {

      this.separatorList = separators;
      this.printService.onDataReady();
    });
  }
}
